import * as React from "react";
import type { SVGProps } from "react";
const SvgYoutube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 640 250"
    {...props}
  >
    <path d="M214.2 81.9c-2.1-7.7-8.1-13.8-15.8-15.9-14-3.8-70.2-3.8-70.2-3.8S72 62.2 58 65.9c-7.7 2.2-13.7 8.3-15.8 16-3.7 14-3.7 43.1-3.7 43.1s0 29.3 3.7 43.1c2.1 7.7 8.1 13.8 15.8 15.9 14.2 3.8 70.2 3.8 70.2 3.8s56.1 0 70.2-3.7c7.7-2.1 13.7-8.1 15.8-15.9 3.7-14 3.7-43.1 3.7-43.1s.1-29.2-3.7-43.2m-103.9 70V98.1L157 125zM299 176.6c-3.5-2.4-6.1-6.1-7.5-11.2-1.5-5-2.2-11.8-2.2-20.1v-11.4c0-8.4.9-15.2 2.5-20.4 1.3-4.6 4.1-8.5 8-11.2 3.6-2.4 8.4-3.5 14.3-3.5s10.5 1.2 14 3.5 6.1 6.2 7.7 11.2 2.5 11.8 2.5 20.4v11.4c0 8.4-.7 15.1-2.4 20.2-1.6 5-4.1 8.9-7.7 11.2s-8.4 3.5-14.5 3.5-11.1-1.3-14.7-3.6m20-12.4c1-2.5 1.5-6.8 1.5-12.6v-24.5c0-5.6-.4-9.8-1.5-12.4s-2.7-3.9-5.2-3.9c-2.4 0-4.1 1.3-5 3.9s-1.5 6.8-1.5 12.4v24.5q0 8.7 1.5 12.6c.6 2.3 2.8 3.9 5.2 3.8 2.3 0 3.9-1.2 5-3.8M571.8 145.4v4c-.1 3.8.1 7.6.4 11.4.3 2.5.9 4.4 1.9 5.5 1.2 1.2 2.8 1.8 4.4 1.8 2.7 0 4.4-1 5.5-3.1s1.5-5.5 1.6-10.4l15.2.9c.1.9.2 1.9.2 2.8 0 7.2-1.9 12.7-5.9 16.2s-9.6 5.3-16.8 5.3c-8.7 0-14.8-2.8-18.3-8.3s-5.3-13.9-5.3-25.4v-13.7c0-11.8 1.8-20.4 5.5-25.9s9.9-8.1 18.6-8.1c6.1 0 10.8 1.2 14 3.4s5.5 5.8 6.8 10.3c1.3 4.7 1.9 11.2 1.9 19.5v13.5h-29.7zm2.2-33.1c-1 1.6-1.7 3.5-1.8 5.5-.4 3.8-.5 7.7-.4 11.5v5.6h13v-5.6c0-3.8-.1-7.7-.4-11.5-.1-2-.8-3.9-1.9-5.5-1.1-1.1-2.6-1.7-4.1-1.6-1.7-.3-3.4.3-4.4 1.6M255.9 143.9 235.8 71h17.6l7.1 33c1.8 8.1 3.1 15.1 4 20.8h.4c.6-4.1 1.9-10.9 4-20.7l7.4-33.1h17.6l-20.4 73v34.9H256v-35zM395.5 100.2V179h-13.9l-1.5-9.6h-.4c-3.7 7.2-9.5 10.9-17 10.9-5.2 0-9-1.8-11.5-5.2s-3.7-8.7-3.7-16.1v-58.8h17.7V158c0 3.5.4 6.1 1.2 7.5.7 1.4 2.2 2.3 3.8 2.2 1.6 0 3.1-.5 4.4-1.5 1.4-.8 2.5-2.1 3.1-3.5v-62.6h17.8zM486.4 100.2V179h-13.9l-1.5-9.6h-.4c-3.7 7.2-9.5 10.9-17 10.9-5.2 0-9-1.8-11.5-5.2s-3.7-8.7-3.7-16.1v-58.8h17.8V158c0 3.5.4 6.1 1.2 7.5.7 1.4 2.2 2.3 3.8 2.2 1.6 0 3.1-.5 4.4-1.5 1.4-.8 2.5-2.1 3.1-3.5v-62.6h17.7z" />
    <path d="M443.7 85.4h-17.6v93.4h-17.3V85.4h-17.6V71.1h52.5zM545 112.7c-1-5-2.8-8.6-5.2-10.8s-5.8-3.4-9.9-3.4c-3.3 0-6.5 1-9.2 2.8-2.8 1.8-5 4.3-6.5 7.2h-.2V67.1h-17v111.7h14.6l1.8-7.4h.4c1.4 2.7 3.5 4.9 6.2 6.4 2.8 1.5 6 2.4 9.2 2.4 6.1 0 10.3-2.8 13.2-8.3s4.3-14.2 4.3-25.9v-12.4c-.2-9-.6-16-1.7-20.9m-16.2 32.1c0 4.5-.2 9-.7 13.5-.2 2.5-1 4.9-2.4 6.9-1.1 1.3-2.7 2.1-4.4 2.1-1.4.1-2.8-.3-4-1-1.3-.8-2.3-1.8-3-3.1v-44.7c.6-2 1.6-3.8 3.1-5.3 1.3-1.3 3-2 4.7-2.1 1.6-.1 3.2.7 4.1 2.1 1 1.5 1.6 3.8 2.1 7.1.5 4.7.7 9.5.6 14.2z" />
  </svg>
);
export default SvgYoutube;

