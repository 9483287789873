export type CaseStudy = {
    youtube_id: string;
    id: string;
    name: string;
    shortname?: string; // optional
    svg?: boolean; // optional
    image?: string; // optional
    credits?: Record<string, any>;
  };

const caseStudies: CaseStudy[] = [
    {
        "name": "Samsung & Rihanna - ANTIdiaRy",
        "id": "antidiary",
        "shortname": "ANTIdiaRy",
        "svg": true,
        "youtube_id": "BMqC874aSp4",
        "image": "public/images/anti_2x.jpg",
        "credits": {
            "Agency": [
                "R/GA / New York"
            ],
            "Clients": [
                "Samsung",
                "tidal"
            ],
            "Executive Creative Director": [
                "Trevor Eld",
                "Tristan Kincaid",
                "Qian Qian"
            ],
            "Creative Director": [
                "Felipe Ferreira",
                "Cesar Marchetti"
            ],
            "Agency Producer": [
                "Helder Santo",
                "Jenny Holmes",
                "Ben Kravitz",
                "Angela Esmilla",
                "Esther Rosenburg",
                "Genevieve Sugalski",
                "Stephen Bernstein",
                "Mister Brumfield"
            ],
            "Content Strategist": [
                "Danny Marsh"
            ],
            "Creative Team": [
                "Kristin O'Halloran",
                "Tim Ferris",
                "Jo Hayes",
                "Marc Mathieu",
                "Kelly Solomon",
                "Erin Riggins",
                "Kristin Harrer",
                "Jesse Coulter",
                "Matt Moller",
                "Sony Joy"
            ],
            "Creative Technologist": [
                "Adam Housman",
                "Frank Yandolino",
                "Lambert Torres",
                "Joe Wanko",
                "Jodha Kandola",
                "Eric Geruldsen",
                "Aaron George",
                "Blake Kus",
                "Brian Kuzma",
                "Andres Pagella",
                "Miles Disch"
            ],
            "Writer": [
                "Madeline Garber"
            ],
            "Designer": [
                "Madeline Garber",
                "Vitor Andrade",
                "Jenny Wu",
                "Rene Van Wonderen"
            ],
            "User Experience Designer": [
                "Xavier Gallego",
                "Oscar Llarena",
                "Ankita Arvind"
            ],
            "Production Company": [
                "72 and Sunny / Los Angeles"
            ],
            "Director": [
                "Brett Foraker",
                "Yoann Lemoine"
            ],
            "Producer": [
                "Kris Pito",
                "Denise Knickerbocker",
                "Randi Cohen",
                "Ashley Vaughan",
                "Kathy Monahan",
                "Diego De La Maza"
            ],
            "Photographer": [
                "Jim Fiscus"
            ],
            "PR / Marketing": [
                "Edelman / New York",
                "Rosetta / New York"
            ]
        }
    },
    {
        "name": "Samsung & Diplo - Can't Stop",
        "shortname": "Can't Stop",
        "id": "cantstop",
        "svg": true,
        "youtube_id": "Jd3VRdgBwxU",
        "image": "public/images/cantstop_2x.jpg",
        "credits": {
            "Agency": [
                "R/GA"
            ],
            "Film Studio": [
                "Hound"
            ],
            "Samsung Team": [
                "Marc Mathieu",
                "Jesse Coulter",
                "Kristin Harrer",
                "John T Field",
                "Monique Nanclares",
                "Kyle Lazarus"
            ],
            "Group Executive Creative Directors": [
                "Chris Northam",
                "Eric Jannon"
            ],
            "Creative Team": [
                "Tristan Kincaid",
                "Trevor Eld",
                "Qian Qian",
                "Thibault Gerard",
                "Katie Facada",
                "Bruno Nakano",
                "Cesar Marchetti",
                "Jenny Wu"
            ],
            "Client Services": [
                "Helder Santo",
                "Chad Wilson",
                "Ben Kravitz"
            ],
            "Production": [
                "Karl Lunan",
                "Maria Jesus Pesara"
            ],
            "Technology": [
                "Adam Housman",
                "Frank Yandolino",
                "Lambert Torres",
                "Andres Pagella",
                "Joe Wanko",
                "Brian Kuzma",
                "Ignacio Tischelman",
                "Jin Sung Yoo",
                "Pablo DiStefano",
                "Pablo Roulet",
                "Nahuel Lema",
                "Nicolas Schteinschraber",
                "Pablo Muratore"
            ],
            "Prototyping": [
                "Michael Piccuirro",
                "Scott Kundert"
            ],
            "Quality Assurance": [
                "Jodha Kandola",
                "Kevin Geruldsen"
            ],
            "Data Science ": [
                "Marc Maleh"
            ],
            "Experience Design": [
                "Shashank Raval",
                "Olya Mikhaliova"
            ],
            "Director, Hound": [
                "Chris Simms"
            ],
            "Music": [
                "Diplo/Mad Decent"
            ]
        }
    },
    {
        "name": "Interstellar Official Website",
        "shortname": "Interstellar",
        "id": "interstellar",
        "svg": true,
        "youtube_id": "4rHVvYDew8k",
        "image": "public/images/interstellar_2x.jpg",
        "credits": {
            "Agency": [
                "Big Spaceship"
            ],
            "Clients": [
                "Google Play",
                "Paramount Pictures"
            ],
            "Design": [
                "Naim Sheriff"
            ],
            "User Experience": [
                "Dabney Donigan"
            ],
            "Production": [
                "Lindsay Brady",
                "Lynn Fasano",
                "Aviar Couillard"
            ],
            "Technology": [
                "Paul Graffam",
                "Thomas McCabe",
                "Ian Solano",
                "Joe Wanko",
                "Eric Willenson"
            ]
        }
    }
]

export default caseStudies;