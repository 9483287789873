import React from 'react';
import classnames from 'classnames';

export enum SectionColor {
  WHITE = 'white',
  GREY = 'grey',
}

export interface SectionProps {
  color?: SectionColor;
  id?: string;
  isFixed?: boolean;
  className?: string;
  header?: string;
}

function Section({
  color = SectionColor.WHITE,
  id,
  isFixed,
  className,
  header,
  children
}: React.PropsWithChildren<SectionProps>) {
  return (
    <section id={id} className={classnames(
      'section',
      [id, color].map(id => (`section--${id}`)),
      className,
      {
        'section--fixed': isFixed
      }
    )}>
      {header && (
        <h3 className="section-header">{header}</h3>
      )}
          {children}
    </section>
  );
}

export default Section;
