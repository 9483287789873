import * as React from "react";
import type { SVGProps } from "react";
const SvgBloomberg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 640 250"
    {...props}
  >
    <path d="M18.2 69.4h44.2c8.4 0 14.7 2.1 19.4 5.6 5.2 4.2 8 10.3 8 17.8 0 8.7-3.5 14-10.5 17.3v.5c9.4 3 14.7 11.5 14.7 21.5 0 9.6-3.7 17.1-9.8 21.8-5.2 3.7-11.7 5.4-20.6 5.4H18.2zM58 104.5c4.7 0 8.2-2.6 8.2-8 0-5.2-3.5-7.7-8.4-7.7H42.6v15.5H58zm2.1 34.9c5.4 0 9.4-3.3 9.4-9.1 0-6.3-4.2-9.1-9.4-9.1H42.8v18.5h17.3zM98.1 69.4h23.2v90.1H98.1zM125.9 127.5c0-20.4 13.1-33.9 33.5-33.9s33.2 13.8 33.2 33.9c0 20.4-12.9 33.9-33.2 33.9s-33.5-13.6-33.5-33.9m43.6 0c0-11.2-3.5-18.3-10.5-18.3s-10.3 7-10.3 18.3S152 146 159 146c7-.3 10.5-7.3 10.5-18.5M195.2 127.5c0-20.4 13.1-33.9 33.5-33.9s33 13.6 33 33.9-12.9 33.9-33.2 33.9c-20.2 0-33.3-13.6-33.3-33.9m43.6 0c0-11.2-3.5-18.3-10.5-18.3s-10.3 7-10.3 18.3 3.3 18.5 10.3 18.5c7-.3 10.5-7.3 10.5-18.5M266.4 95.1h21.5v9.6h.5c4.2-7.5 10.1-11.5 19.4-11.5 8.2 0 14.3 4 17.3 11h.5c5.2-8.4 11.9-11 19.9-11 15 0 21.3 11 21.3 25.8v40.3h-22.5V123c0-6.6-2.1-10.8-7.7-10.8s-8.4 5.2-8.4 12.2v34.9h-22.5V123c0-6.6-2.1-10.8-7.7-10.8s-8.4 5.2-8.4 12.2v34.9h-23.2zM395.2 150.9h-.2v8.4h-21.5V69.4H396v32.3h.5c4.2-5.6 10.1-8.7 18.3-8.7 16.2 0 25.5 14.5 25.5 33.9 0 21.8-10.1 34.4-26.9 34.4-7.2.1-14.5-3.2-18.2-10.4m21.7-24.1c0-10.3-3.7-17.1-10.5-17.1-7 0-11.2 6.8-11.2 17.1s4.4 17.1 11.2 17.1 10.5-6.6 10.5-17.1M442.7 127.2c0-20.1 13.3-33.9 32.5-33.9 9.6 0 16.9 3 22.5 8.4 7.5 7.3 10.8 18.5 10.5 31.6h-43.1c.9 8 4.7 12.2 11.5 12.2 4 0 7.5-1.9 8.9-6.1h21.8c-3.7 14.7-15.2 22-31.4 22-19.9-.2-33.2-13.8-33.2-34.2m22.5-6.8h20.6c-.5-7.7-4.7-11.7-10.1-11.7-6.3-.2-9.8 4.2-10.5 11.7M533.7 106.2h.5c4.7-8.9 10.8-12.4 18.5-12.4 1.9 0 3 .2 3.7.7v19.7h-.4c-13.3-2.3-21.1 4-21.1 18.5v26.9h-22.7V95.2h21.5zM558.8 159.5h21.8c.9 2.8 3.7 5.4 9.1 5.4 7.3 0 9.8-4.4 9.8-11.2V149h-.5c-3.5 4.2-8.4 7.3-15.5 7.3-13.8 0-26-10.3-26-30.4 0-18.5 9.8-32.8 24.8-32.8 8.7 0 13.8 3.5 17.3 8.9h.5v-7h21.5v55.7c0 10.3-3.5 18.3-8.9 22.9-5.6 4.9-13.6 7-22.7 7-17.2.2-29.1-6.8-31.2-21.1m41.7-34.6c0-8-3.7-14.5-10.3-14.5-6.3 0-10.3 5.6-10.3 14.5 0 8.7 4 14.7 10.3 14.7s10.3-6.3 10.3-14.7" />
  </svg>
);
export default SvgBloomberg;

