import * as React from "react";
import type { SVGProps } from "react";
const SvgEnvelope = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 4054.32 4054.32"
    {...props}
  >
    <path d="M2869.3 2645.91H1185V1477.39h1684.3Zm-1609.28-75H2794.3V1552.39H1260Z" />
    <path d="m2027.16 2137.76-826.49-592.39 43.7-60.96 782.79 561.08 782.79-561.08 43.69 60.96z" />
  </svg>
);
export default SvgEnvelope;

