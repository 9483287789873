type Client = {
    name: string;
    key: string;
}

const clients: Client[] = [
    {
        name: "Squarespace",
        key: "squarespace"
    },
    {
        name: "Bloomberg",
        key: "bloomberg"
    },
    {
        name: "Google",
        key: "google"
    },
    {
        name: "Samsung",
        key: "samsung"
    },
    {
        name: "YouTube",
        key: "youtube"
    },
    {
        name: "Paramount Pictures",
        key: "paramountPictures"
    },
    {
        name: "BMW",
        key: "bmw"
    },
    {
        name: "Tidal",
        key: "tidal"
    }
]

export default clients;