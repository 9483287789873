import React, { FC, MouseEvent, useState, useCallback } from 'react';
import Section, { SectionProps } from '../Components/Section';
import Svgs from '../Svgs';
import { CaseStudy } from '../data/caseStudies';
import Modal from 'react-modal';
import Player from 'react-player';

const { play: Play } = Svgs;

type ImageSrcSet = {
  url: string;
  size: string;
};

interface CaseStudyComponentProps extends SectionProps {
  caseStudies: CaseStudy[];
}

const CaseStudies: FC<CaseStudyComponentProps> = ({ caseStudies }: CaseStudyComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);

  const handleClick = useCallback((e: MouseEvent, youtubeId: string) => {
    e.preventDefault();
    setSelectedVideoId(youtubeId);
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setSelectedVideoId(null);
  }, []);

  return (
    <Section
      id='case-studies'
      header='select case studies'
    >
      <div className="case-study--wrapper">
        {caseStudies.map((caseStudy) => {
          const Svg = Svgs[caseStudy.id];

          return (
            <a
              key={caseStudy.id}
              href={`https://www.youtube.com/watch?v=${caseStudy.youtube_id}`}
              className={`case-study case-study--${caseStudy.id}`}
              data-modal-link
              data-modal-link-id={`modal--${caseStudy.youtube_id}`}
              data-modal-disable-rel="true"
              data-modal-youtube-id={caseStudy.youtube_id}
              data-modal-key={caseStudy.id}
              onClick={(e) => handleClick(e, caseStudy.youtube_id)}
            >
              <div className="case-study--play-button-wrapper">
                <Play className="case-study--play-button" />
              </div>
              <div className="case-study--title-wrapper">
                {caseStudy.svg && Svg ? (
                  <Svg className="case-study--logo" aria-label={`${caseStudy.name} Logo`} />
                ) : (
                  <h4 className="case-study--title">{caseStudy.shortname || caseStudy.name}</h4>
                )}
              </div>
              {caseStudy.image && <img src={caseStudy.image} alt={caseStudy.name} aria-hidden="true" />}
            </a>
          );
        })}
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Case Study Video"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '80%',
            padding: '0',
            border: 'none',
            borderRadius: '8px',
            overflow: 'hidden',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 1000,
          },
        }}
      >
        {selectedVideoId && (
          <Player
            url={`https://www.youtube.com/watch?v=${selectedVideoId}`}
            controls
            playing
            width="100%"
            height="100%"
          />
        )}
      </Modal>
    </Section>
  );
}

export default CaseStudies;
