import * as React from "react";
import type { SVGProps } from "react";
const SvgSamsung = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 640 250"
    {...props}
  >
    <path d="m511.6 82.7 1.2 66h-.5l-19.3-66h-31.2v83.2h20.7l-1.2-68.2h.5l20.7 68.2h29.9V82.7zm-395.6 0-15.6 84h22.7l11.5-76.2h.5l11.2 76.3h22.6l-15.5-84zm127 0L232.6 147h-.5l-10.4-64.3h-34.3l-1.9 84h21l.5-75.6h.5l14 75.6H243l14-75.5h.5l.5 75.6h21l-1.9-84zM65 143c.7 2 .7 4.2.2 6.2-.7 2.8-2.6 5.6-8.2 5.6-5.3 0-8.5-3-8.5-7.6v-8.1H26v6.5c0 18.8 14.8 24.4 30.5 24.4 15.2 0 27.7-5.2 29.7-19.2.7-4.6.7-9.2-.1-13.8-3.6-17.6-35.4-22.9-37.8-32.7-.3-1.5-.4-3-.1-4.4.6-2.7 2.4-5.6 7.7-5.6 4.9 0 7.8 3 7.8 7.6v5.2h21v-5.9C84.7 82.9 68.3 80 56.4 80 41.5 80 29.3 85 27 98.6c-.7 3.8-.6 7.6.2 11.4 3.6 17.2 33.5 22.1 37.8 33m273.2-.2c.6 2 .7 4.1.1 6.1-.7 2.8-2.5 5.6-8.1 5.6-5.2 0-8.4-3-8.4-7.5v-8h-22.3v6.4c0 18.6 14.6 24.2 30.2 24.2 15.1 0 27.4-5.1 29.4-19 .7-4.5.7-9.1-.1-13.6-3.5-17.4-35.1-22.6-37.4-32.4-.3-1.4-.4-2.9-.1-4.4.6-2.7 2.4-5.6 7.6-5.6 4.9 0 7.7 3 7.7 7.5v5.1h20.8v-5.9c0-18.1-16.3-21-28.1-21-14.8 0-26.9 4.9-29.1 18.5-.7 3.7-.6 7.5.2 11.2 3.8 17.1 33.4 22.1 37.6 32.8m70.6 11.3c5.9 0 7.6-4 8.1-6.1.2-1.1.2-2.1.2-3.2V82.7h21.3v60.2c0 1.5-.1 4.7-.2 5.5-1.5 15.7-13.9 20.8-29.3 20.8s-27.9-5.1-29.3-20.8c-.1-.8-.2-4-.2-5.5V82.7h21.3v62.2c0 1.1 0 2.1.2 3.2.2 2 2 6 7.9 6m175.3-.8c6.1 0 8.2-3.9 8.6-6.1.2-1.1.2-2.1.2-3.2v-12.2h-8.6v-12.2H614v22.5c0 1.6 0 2.7-.3 5.5-1.4 15.3-14.7 20.8-29.6 20.8s-28.2-5.5-29.6-20.8c-.2-2.8-.3-4-.3-5.5v-35.4c0-1.5.2-4.1.3-5.5 1.9-15.7 14.6-20.8 29.5-20.8s28 5 29.5 20.8c.2 1.8.2 3.7.2 5.5v2.8h-21.2v-4.7c0-1.1-.1-2.1-.3-3.2-.4-1.9-2-6.1-8.4-6.1-6.1 0-7.9 4-8.4 6.1-.2 1.3-.4 2.7-.3 4V144c0 1.1 0 2.1.2 3.2.6 2.2 2.7 6.1 8.8 6.1" />
  </svg>
);
export default SvgSamsung;

