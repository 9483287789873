import * as React from "react";
import type { SVGProps } from "react";
const SvgWorried = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
    <path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30c16.571 0 30.002-13.432 30.002-30S48.57 2 32 2m0 57.5C16.836 59.5 4.5 47.164 4.5 32S16.836 4.5 32 4.5 59.502 16.836 59.502 32 47.164 59.5 32 59.5" />
    <circle cx={20.5} cy={29.59} r={5} />
    <circle cx={43.502} cy={29.59} r={5} />
    <path d="M52.619 17.051a16.42 16.42 0 0 0-13.492-3.615c-.703.135-.193 2.27.385 2.156 4.17-.748 8.457.4 11.693 3.133.443.386 1.955-1.205 1.414-1.674M24.488 15.441c.578.113 1.09-2.021.387-2.156A16.43 16.43 0 0 0 11.383 16.9c-.541.469.969 2.063 1.412 1.674a14.24 14.24 0 0 1 11.693-3.133M40.582 44.428c-5.404-2.538-11.788-2.54-17.197-.012-1.339.645.329 4.15 1.662 3.5 3.571-1.665 8.896-2.306 13.875.01 1.334.619 3.078-2.813 1.66-3.498" />
  </svg>
);
export default SvgWorried;

