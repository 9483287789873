
import antidiary from './antidiary';
import bloomberg from './bloomberg';
import bmw from './bmw';
import cantstop from './cantstop';
import chobani from './chobani';
import close from './close';
import envelope from './envelope';
import fredSegal from './fredSegal';
import google from './google';
import interstellar from './interstellar';
import paramountPictures from './paramountPictures';
import play from './play';
import samsung from './samsung';
import signature from './signature';
import signatureNoBorder from './signatureNoBorder';
import squarespace from './squarespace';
import tidal from './tidal';
import worried from './worried';
import youtube from './youtube';

type Svgs = Record<string, React.FC<React.SVGProps<SVGSVGElement>>>;

const svgs: Svgs = {
  antidiary,
  bloomberg,
  bmw,
  cantstop,
  chobani,
  close,
  envelope,
  fredSegal,
  google,
  interstellar,
  paramountPictures,
  play,
  samsung,
  signature,
  signatureNoBorder,
  squarespace,
  tidal,
  worried,
  youtube,
};

export default svgs;
