import * as React from "react";
import type { SVGProps } from "react";
const SvgGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 640 250"
    {...props}
  >
    <path d="M276.5 130.2c0 28.6-22.4 49.6-49.8 49.6s-49.8-21.1-49.8-49.6c0-28.8 22.4-49.7 49.8-49.7s49.8 20.9 49.8 49.7m-21.8 0c0-17.9-13-30.1-28-30.1s-28 12.2-28 30.1c0 17.7 13 30.1 28 30.1s28-12.4 28-30.1M384 130.2c0 28.6-22.4 49.6-49.8 49.6s-49.8-21.1-49.8-49.6c0-28.8 22.4-49.7 49.8-49.7s49.8 20.9 49.8 49.7m-21.8 0c0-17.9-13-30.1-28-30.1s-28 12.2-28 30.1c0 17.7 13 30.1 28 30.1s27.9-12.4 28-30.1M486.9 83.6v89.1c0 36.7-21.6 51.6-47.2 51.6-24.1 0-38.5-16.1-44-29.2l19-7.9c3.4 8.1 11.7 17.6 25 17.6 16.4 0 26.5-10.1 26.5-29.1v-7.1h-.8c-4.9 6-14.3 11.3-26.1 11.3-24.8 0-47.6-21.6-47.6-49.4 0-28 22.7-49.8 47.6-49.8 11.8 0 21.2 5.3 26.1 11.1h.8v-8.1zm-19.1 46.8c0-17.5-11.7-30.3-26.5-30.3-15 0-27.6 12.8-27.6 30.3 0 17.3 12.6 29.9 27.6 29.9 14.8 0 26.5-12.6 26.5-29.9M521 31.3v145.5h-21.2V31.3zM603.9 146.5l16.9 11.3c-9.2 13.8-24.8 22.1-41.4 22-28.2 0-49.3-21.8-49.3-49.6 0-29.5 21.2-49.6 46.8-49.6 25.8 0 38.4 20.5 42.5 31.6l2.3 5.6-66.4 27.5c5.1 10 13 15 24.1 15s18.9-5.5 24.5-13.8m-52.1-17.8 44.4-18.4c-2.4-6.2-9.8-10.5-18.4-10.5-11.1 0-26.6 9.7-26 28.9M96.4 117.3V96.2h71c.7 4.2 1.1 8.5 1 12.7 0 15.8-4.3 35.3-18.2 49.3-13.5 14.1-30.8 21.6-53.8 21.6-42.5 0-78.2-34.6-78.2-77.1s35.8-77 78.2-77c23.5 0 40.2 9.2 52.8 21.2l-14.9 14.9c-10.2-9.8-23.9-15.2-38-15-31 0-55.3 25-55.3 56s24.3 56 55.3 56c20.1 0 31.6-8.1 38.9-15.4 6-6 9.9-14.5 11.4-26.1z" />
  </svg>
);
export default SvgGoogle;

