import * as React from "react";
import type { SVGProps } from "react";
const SvgChobani = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 640 250"
    {...props}
  >
    <path d="m428.2 131.1 11.4-23.2 11.5 23.2zm11.3-49L415 131.2h-12.3v12.2h6.1L396.6 168h13.5l12.4-24.6h34.3l12.3 24.6h13.4zM86.6 169.1c-24.4 0-44.1-19.8-44.1-44.2s19.8-44.1 44.2-44.1c11.8 0 22.3 4.4 29.9 12.2l-8.4 8.7c-5.7-5.7-13.5-8.8-21.6-8.7-16.8 0-31.9 14.3-31.9 32s14.9 31.8 31.9 31.8c8.1.1 16-3 21.8-8.7l8.4 8.7c-8 8-18.9 12.4-30.2 12.3M200.4 167.9h-12.3v-36.8h-45.9v36.8h-12.4v-36.8h-9.2v-12.2h9.2V82.1h12.4v36.8h45.9V82.1h12.4zM567.1 167.9l-61.4-55.2v55.2h-12.2V82.1l61.3 55.2V82.1h12.3zM597.5 167.9h-12.2V82.1h12.2zM256.5 156.9c-17.6 0-31.9-14.4-31.8-32s14.4-31.9 32-31.8c17.6 0 31.9 14.4 31.8 32 0 17.6-14.3 31.8-32 31.8m0-76c-24.4 0-44.2 19.7-44.2 44.1s19.7 44.2 44.1 44.2 44.2-19.7 44.2-44.1-19.7-44.1-44.1-44.2M364.5 155.7h-33.7v-24.6h33.7c6.8 0 12.3 5.5 12.3 12.3s-5.5 12.3-12.3 12.3m-33.7-61.4h33.7c6.8.2 12.1 5.9 11.9 12.6-.2 6.5-5.4 11.8-11.9 11.9h-33.7zm49.3 31c5.5-4.6 8.8-11.4 9-18.7-.1-13.6-11-24.5-24.6-24.6h-46v36.8h-9.2V131h9.2v36.8h46c13.5-.1 24.4-11.1 24.5-24.6 0-6.9-3.4-13.6-8.9-17.9" />
  </svg>
);
export default SvgChobani;

