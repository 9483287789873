type ContactLink = {
    name: string;
    linkText: string;
    url: string;
    target?: string;
}

const contactLinks: Array<ContactLink> = [
    {
        name: "email",
        linkText: "yo@joewanko.com",
        url: "mailto:yo@joewanko.com"
    },
    {
        name: "instagram",
        linkText: "instagram.com/jwanko1",
        url: "https://www.instagram.com/jwanko1",
        target: "_blank"
    },
    {
        name: "linkedin",
        linkText: "linkedin.com/in/jwanko",
        url: "https://www.linkedin.com/in/jwanko",
        target: "_blank"
    }
];

export default contactLinks;