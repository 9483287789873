import * as React from "react";
import type { SVGProps } from "react";
const SvgBmw = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 640 250"
    {...props}
  >
    <path d="M320 0c-69 0-125 56-125 125s56 125 125 125 125-56 125-125S389 0 320 0m0 245.7c-66.7 0-120.7-54-120.7-120.7S253.4 4.3 320 4.3c66.7 0 120.7 54 120.7 120.7s-54 120.7-120.7 120.7" />
    <path d="M320 9.3c-63.9 0-115.7 51.8-115.7 115.7S256.1 240.7 320 240.7 435.7 188.9 435.7 125 383.9 9.3 320 9.3m-16.9 6.3h9.2l8.3 21.7 8.3-21.7h9.2V46H332V24.8l-8 21.3h-6.7l-8-21.3v21.3h-6.1zm-76.9 50.1L241.1 48c3.1-3.7 7.4-6.6 11.5-3.2 1.4 1.1 2.6 2.5 3.3 4.2.5 1.5.5 3.1 0 4.6 3.1-2.1 7.2-1.7 9.8.9 3.8 3.7 3.2 7.9-.4 12.2l-15.7 18.7zm146 111.6c-28.9 28.9-75.7 28.9-104.5 0s-28.9-75.7 0-104.5 75.7-28.9 104.5 0c28.9 28.8 28.9 75.6 0 104.5.1-.1.1 0 0 0m16.7-91.4-5.5-6.9 11-16.1-.1-.1-18 7.4-5.6-6.8 19.1-24.6 4.7 5.8-12 15.7 18.3-7.9 5.1 6.2L394.4 75l17.8-8.4 4.7 5.8z" />
    <path d="M250.9 125H320V55.9c-38.2 0-69.1 30.9-69.1 69.1M320 194.1c38.2 0 69.1-31 69.1-69.1H320zM250 56.9c1-1.4.7-3.4-.7-4.5-1.5-1.3-3.9-1.3-4.9-.1L235.5 63l5.5 4.7 8.8-10.5c.1-.1.1-.2.2-.3M260.1 64.8c1.3-1.7.9-4.1-.8-5.3-1.5-1.1-3.6-1-5 .3L245 71l5.8 4.9z" />
  </svg>
);
export default SvgBmw;

