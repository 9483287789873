import * as React from "react";
import type { SVGProps } from "react";
const SvgTidal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 640 250"
    {...props}
  >
    <path d="M243.8 160.5h-14.4v-51h-18.6V97.2h51.5v12.3h-18.6v51zM319.8 160.5h-14.5V97.2h14.5zM575 148h25v12.5h-39.5V97.2H575zM368 97.2h23.4c17.3 0 34.5 9.1 34.5 31.5 0 21-16.9 31.8-33.6 31.8H368zm14 50.9h9c12.1 0 20.1-7.5 20.1-19.5 0-11.4-8.1-19.1-19.8-19.1H382zM506.1 160.5H522l-26.1-63.3H481l-26.3 63.3h15.5l4.7-12.5h26.7zm-27.5-23.7 9.5-24.8 9.5 24.8zM114.2 106.5 95.6 125l-18.5-18.5L95.6 88zM114.2 143.5 95.6 162l-18.5-18.5L95.6 125zM77.1 106.5 58.6 125 40 106.5 58.6 88z" />
    <path d="M151.2 106.5 132.7 125l-18.5-18.5L132.7 88z" />
  </svg>
);
export default SvgTidal;

